import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { baseUrl, sgUrl, FIREBASE_CONFIG } from 'src/environments/environment';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, set, get, onValue, update, remove, Database } from 'firebase/database';
import { ModalController, NavController, Platform, IonRouterOutlet } from '@ionic/angular';
import { DataService } from './data.service';
import { AltomateService } from './altomate.service';
import { Market } from '@ionic-native/market/ngx';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Options } from './options';
// import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import { UpdatePage } from './update/update.page';
import * as moment from 'moment'
import { OpenMobilePage } from './open-mobile/open-mobile.page';
declare var _kmq;
import { SafeArea } from 'capacitor-plugin-safe-area';

import { ActionPerformed, PushNotifications, PushNotificationSchema } from "@capacitor/push-notifications";
import { FCM } from "@capacitor-community/fcm";
import { NotificationsPage } from './notifications/notifications.page';
import TimeAgo from 'javascript-time-ago'
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
import { query, orderByChild, equalTo } from 'firebase/database';

// English.
import en from 'javascript-time-ago/locale/en'
import { FirebaseService } from './firebase.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  currentPlatform = ''
  bundle = {}
  userInfo = {}

  showNotification = false

  public optionsList2: Options[] = [
    {
      value: `Where does it come from ?
        Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.`,
    },
    {
      value: `Where can I get some?
        There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.`,
    },
    { value: 'Bitter Gourd' },
    { value: 'Pumpkin' },
    { value: 'Bottle Gourd' },
  ];

  weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  dates = []

  notificationLists = []
  allnotificationLists = []


  timeAgo
  companies = []
  default = ''
  userid = ''
  currentUserEmail = ''
  searchCompanyKeyword = ''
  searchComapnyResult = []
  firstInit = true
  isAdmin = false
  authority = []
  private database: Database;


  constructor(
    private dataService: DataService,
    private platform: Platform,
    private altomateService: AltomateService,
    private market: Market,
    private http: HttpClient,
    private router: Router,
    private nav: NavController,
    // private fcm: FCM,
    private renderer: Renderer2,
    private modalCtrl: ModalController,
    private firebaseService: FirebaseService
  ) {

    this.database = this.firebaseService.getDatabaseInstance();



    TimeAgo.addDefaultLocale(en)
    this.timeAgo = new TimeAgo('en-US');

    //console.log()



    // this.SafeArea.enable({
    //   customColorsForSystemBars: true,
    //   statusBarColor: '#00000000', // transparent
    //   statusBarContent: 'light',
    //   navigationBarColor: '#00000000', // transparent
    //   navigationBarContent: 'light',
    // });




    // ws.send('Hello, server!');

    // // Sending JSON
    // const data = {
    //     type: 'chat',
    //     message: 'Hello, server!',
    // };
    // ws.send(JSON.stringify(data));



    let iosVersion = '020005'
    let androidVersion = '020005'

    let days = []

    let initialPoint = moment()

    for (let i = 0; i < 7; i++) {
      days.push(moment(initialPoint).day(this.weekDays[i]));
    }

    days.forEach(day => this.dates.push(day.format('DD-MM-YYYY')));

    // const analytics = analytics();

    // analytics.setUserProperties({
    //   userType: 'new', // Example property
    //   preferredLanguage: 'en' // Add as many properties as you need
    // });


    // analytics.logEvent('just a testing event', {
    //   your_parameter_name: 'testing'
    // });


    this.platform.ready().then(async () => {
      // console.log(this.platform.platforms())

      if (this.platform.is('mobileweb') && (this.platform.is('android') || this.platform.is('ios'))) {
        // console.log()
        setTimeout(() => {
          if (this.altomateService.isNullOrEmpty(localStorage.getItem('openmobilebrowser')) || (Date.now() > parseInt(localStorage.getItem('openmobilebrowser')))) {
            this.openMobile((this.platform.is('ios') ? 'id6449769620' : 'com.altomate.io'))
          }
        }, 0);
      }

      if (!this.platform.is('mobileweb') && this.platform.is('android')) {
        onValue(ref(this.database, '/versioning/android/' + androidVersion), (data) => {
          if (data.val() == false) {
            setTimeout(() => {
              this.showUpdate('com.altomate.io')
            }, 0);
          }
        });
      }

      if (!this.platform.is('mobileweb') && (this.platform.is('ios'))) {
        onValue(ref(this.database, '/versioning/ios/' + iosVersion), (data) => {
          if (data.val() == false) {
            setTimeout(() => {
              this.showUpdate('id6449769620')
            }, 0);
          }
        });
      }

      if (!this.platform.is('mobileweb') && (this.platform.is('ios') || this.platform.is('android'))) {
        await PushNotifications.requestPermissions();
        await PushNotifications.register();
      }


      this.currentPlatform = this.platform.is('ios') ? 'ios' : 'android'


      this.dataService.applicationDetails.subscribe(async (res) => {
        //console.log(res)
        this.companies = res.map(r => ({ country: r['country'], id: r['id'], name: r['type'] == 'enterprise_partnership' ? r['company_name'] : r['confirmedName'] || '' })) //only confirmed name will show at the list

        this.companies = this.companies.filter(vb => vb['id'] != 'undefined')
        // <----- FusionETA - Alvin (4 January  2024) - name list - Start ----->
        this.companies = this.companies.filter(company => company.name !== '');


        //console.log(this.companies)

        // <----- FusionETA - Alvin (4 January  2024) - name list - End ----->
        if (this.companies.length > 0) {
        }
      })




      // this.getFCMToken()
      this.altomateService.getRequestDetails().then((res: any) => {
        this.dataService.updateRequestDetails(res)
      })

      // setTimeout(() => {
      //   this.altomateService.pleasewait('Please wait..', 'Checking your authentication')
      // }, 0);


      const auth = getAuth();

      onAuthStateChanged(auth, async (user) => {
        if (user) {


          this.currentUserEmail = user.email
          localStorage.setItem('login', '1234')
          // this.altomateService.swalclose()
          // this.nav.navigateRoot('tabs/tab1')

          // this.nav.navigateRoot('tabs/tab1')
          if (!this.platform.is('mobileweb') && (this.platform.is('android') || this.platform.is('ios'))) {
            // this.fcm.subscribeToTopic(user.uid || '').catch((error) => {
            //   //  console.log(error)
            // })

            if (!this.platform.is('mobileweb') && (this.platform.is('android') || this.platform.is('ios'))) {
              FCM.subscribeTo({ topic: (user.uid || '') })
            }


          }

          if (localStorage.getItem('XERO_ACCESS_TOKEN_KEY') && localStorage.getItem('XERO_REFRESH_TOKEN_KEY')) {
            await this.altomateService.getXeroConnectedOrganisation()
          }

          this.altomateService.pleasewait('Please wait...', 'Getting your data...')

          const userRef = ref(this.database, `users/${user.uid}`);
          onValue(userRef, (userInfo) => {
            if (userInfo.exists()) {

              const userDetails = userInfo.val();
              this.dataService.updateUser(userDetails);

              this.isAdmin = false;
              if (userDetails['companies'] && userDetails['companies'][userDetails['default']]) {
                this.isAdmin = userDetails['companies'][userDetails['default']]['isAdmin'] == null ? false : userDetails['companies'][userDetails['default']]['isAdmin'];

                if (this.isAdmin === true) {
                  this.authority = userDetails['companies'][userDetails['default']]['authority'];
                  localStorage.setItem('authority', JSON.stringify(this.authority));
                } else {
                  localStorage.removeItem('authority');
                }
              }
            }
          });


          //console.log(user.uid)

          const notificationsRef = ref(this.database, 'notifications');
          const notificationsQuery = query(notificationsRef, orderByChild('uid'), equalTo(user.uid));
          get(notificationsQuery).then((snapshot) => {
            if (snapshot.exists()) {
              this.notificationLists = Object.values(snapshot.val()).sort((a, b) => b['date'] - a['date']);
            }
          });


          const notificationsRef2 = ref(this.database, 'notifications');

          // Create a query to filter notifications by user UID
          const notificationsQuery2 = query(notificationsRef2, orderByChild('uid'), equalTo(user.uid));

          // Fetch the data once
          get(notificationsQuery2).then((snapshot) => {
            if (snapshot.exists()) {
              // Sorting notifications by date in descending order
              this.notificationLists = Object.values(snapshot.val()).sort((a, b) => b['date'] - a['date']);
            }
          });

          const defaultRef = ref(this.database, `users/${user.uid}`);

          onValue(defaultRef, async (userInfo) => {
            this.userid = user.uid
            let userDetails = userInfo.val()
            this.userInfo = userInfo.val()
            this.dataService.updateUser(userDetails)

            //console.log(this.userInfo)

            this.isAdmin = false

            if (this.userInfo['companies'] && this.userInfo['companies'][this.userInfo['default']]) {
              // console.log(this.userInfo['companies'])
              this.isAdmin = this.userInfo['companies'][this.userInfo['default']]['isAdmin'] == null ? false : this.userInfo['companies'][this.userInfo['default']]['isAdmin']


              if (this.isAdmin == true) {
                this.authority = this.userInfo['companies'][this.userInfo['default']]['authority']

                localStorage.setItem('authority', JSON.stringify(this.authority))
              }

              else {
                //console.log('ah')

                localStorage.removeItem('authority')
              }
              //console.log(this.isAdmin, 'check admin')
            }



            if (user.email != 'superadmin@altomate.io') {
              const companiesRef = ref(this.database, `users/${user.uid}/companies`);

              // Query to order by 'id'
              const companiesQuery = query(companiesRef, orderByChild('id'));

              get(companiesQuery).then((snapshot) => {
                if (snapshot.exists()) {
                  snapshot.forEach((apps) => {
                    const appData = apps.val();

                    if (appData['id'] !== 'undefined') {
                      // If 'id' is valid, fetch application details
                      this.altomateService.getApplicationDetails(appData['id']);
                    }
                  });
                }
              }).catch((error) => {
                console.error("Error fetching data:", error);
              });

            }


            const defaultRef = ref(this.database, `users/${user.uid}/default`);
            onValue(defaultRef, async (def) => {
              if (def.exists()) {
                this.default = def.val()

                this.isAdmin = false

                if (this.userInfo['companies'] && this.userInfo['companies'][this.default]) {
                  // console.log(this.userInfo['companies'])
                  this.isAdmin = this.userInfo['companies'][this.default]['isAdmin'] == null ? false : this.userInfo['companies'][this.userInfo['default']]['isAdmin']

                  if (this.isAdmin == true) {
                    this.authority = this.userInfo['companies'][this.default]['authority']
                    localStorage.setItem('authority', JSON.stringify(this.authority))
                  } else {
                    //console.log('ah')

                    localStorage.removeItem('authority')
                  }

                }



                if (document.getElementsByClassName('ng-value').length > 0) {
                  setInterval(() => {
                    if (this.companies.length > 0) {
                      let valueContainer = document.querySelector('#container.ng-select-single .ng-select-container .ng-value-container');
                      if (valueContainer) {
                        let firstChild: any = valueContainer.firstElementChild;
                        if (firstChild && firstChild.tagName === 'IMG') {
                          // <----- FusionETA - Alvin (3 January 2024) - Flag Fix - Start ----------->
                          const currentCompany = this.companies.find(r => r['id'] == this.default) || {}
                          firstChild['src'] = (currentCompany['country'] === 'Singapore' ? 'assets/img/singapore.webp' : (currentCompany['country'] === 'Malaysia' ? 'assets/img/malaysia.webp' : ''));

                        } else {//when empty
                          let imgElement = document.createElement('img');
                          //imgElement.src = this.companies.find(cs => cs['country'] == 'Singapore') ? 'assets/img/singapore.webp' : 'assets/img/malaysia.webp';
                          imgElement.style.marginRight = '10px';
                          imgElement.style.width = '28px';
                          imgElement.style.height = '25px';
                          imgElement.style.objectFit = 'cover';
                          imgElement.style.position = 'absolute';
                          imgElement.style.left = '10px';
                          imgElement.style.marginBottom = '2px';
                          valueContainer.insertBefore(imgElement, valueContainer.firstChild);
                        }
                      }
                      // <---- FusionETA - Alvin (3 January 2024) - Flag Fix - End ----------------->
                    }

                  }, 1000)
                }


                const defaultRef = ref(this.database, `applications/${def.val()}`);
                onValue(defaultRef, async (apps2) => {
                  if (apps2.exists) {
                    //console.log(def.val())
                    if (this.altomateService.firstInitial2 == true) {
                      this.altomateService.firstInitial2 = false
                      this.altomateService.swalclose()
                    }

                    let defaultApplication = apps2.val()
                    this.dataService.removeDocuments(defaultApplication['dealId'])
                    let documents = defaultApplication['documents'] ? Object.values(defaultApplication['documents'] || {}).map((s: any) => ({ ...s, name: (s['id'] == 'declaration' ? s.name + ' - ' + (userDetails['first_name'] + ' ' + userDetails['last_name']) : s.name), companyID: defaultApplication['companyID'], confirmedName: defaultApplication['confirmedName'], applicationId: defaultApplication['id'], dealId: defaultApplication['dealId'], folderId: defaultApplication['folderId'] })) : ''
                    this.dataService.updatedocuments(documents)

                    // this.altomateService.getApplicationDetails(defaultApplication['id'])

                    if (!this.altomateService.isNullOrEmpty(defaultApplication['xeroContactId'])) {
                      // this.http.post(baseUrl + '/getInvoiceByContactId', { contactId: defaultApplication['xeroContactId'] }).subscribe((inv) => {
                      //   this.dataService.updateInvoices(inv['invoices'])
                      //   console.log(inv)
                      // })
                      if (defaultApplication['country'] == 'Malaysia') {
                        this.http.post(baseUrl + '/getInvoiceByContactId', { contactId: defaultApplication['xeroContactId'] }).subscribe((inv) => {
                          this.dataService.updateInvoices(inv['invoices'])
                        })
                      } else if (defaultApplication['country'] == 'Singapore') {
                        this.http.post(sgUrl + '/getInvoiceByContactId', { contactId: defaultApplication['xeroContactId'] }).subscribe((inv) => {
                          this.dataService.updateInvoices(inv['invoices'])
                        })
                      }

                    }

                    if (!this.altomateService.isNullOrEmpty(defaultApplication['folderId'])) {
                      this.http.post(baseUrl + '/getFolder', { storageId: defaultApplication['folderId'] }).subscribe((fol) => {
                        if (fol['data']) {
                          this.dataService.updateFolders(fol['data'])
                        }
                      })
                    }

                    if (!this.altomateService.isNullOrEmpty(defaultApplication['workgroup'])) {
                      // this.http.post(baseUrl + '/getEvent', { storageId: defaultApplication['folderId'] }).subscribe((fol) => {
                      //   if (fol['data']) {
                      //     this.dataService.updateFolders(fol['data'])
                      //   }
                      // })
                      let startDate = moment().startOf('month').format('YYYY-MM-DD')
                      let endDate = moment().endOf('month').format('YYYY-MM-DD')
                      this.altomateService.getCalendarEvent(defaultApplication['workgroup'], startDate, endDate)
                      this.altomateService.getCurrentWeekCalendarEvent(defaultApplication['workgroup'], this.dates[0], this.dates[this.dates.length - 1])
                    }

                    if (!this.altomateService.isNullOrEmpty(defaultApplication['companyID'])) {

                      const customPdfRef = ref(this.database, 'customPdf/');
                      const customPdfQuery = query(customPdfRef, orderByChild('companyId'), equalTo(defaultApplication['companyID']));

                      const customPdfSnapshot = await get(customPdfQuery);
                      if (customPdfSnapshot.exists()) {
                        const arr = Object.values(customPdfSnapshot.val() || {});
                        for (let x = 0; x < arr.length; x++) {
                          const documents = [{
                            ...(arr[x] as any),
                            id: arr[x]['id'].toString(),
                            companyID: defaultApplication['companyID'],
                            confirmedName: defaultApplication['confirmedName'],
                            applicationId: defaultApplication['id'],
                            dealId: arr[x]['dealId'],
                            folderId: defaultApplication['folderId']
                          }];
                          this.dataService.updatedocuments(documents);
                        }
                      }



                      //console.log(defaultApplication['companyID'])
                      const requestDealRef = ref(this.database, `requestdeal/${defaultApplication['companyID']}`);

                      onValue(requestDealRef, (snapshot) => {
                        const requests = snapshot.val();

                        // Update the request deal in the service
                        this.dataService.updateRequestDeal(Object.values(requests || {}));

                        // Process documents if available
                        const arr = Object.values(requests || {});
                        for (let x = 0; x < arr.length; x++) {
                          if (arr[x]['documents']) {
                            const documents = Object.values(arr[x]['documents'] || {}).map((r2: any) => ({
                              ...r2,
                              companyID: defaultApplication['companyID'],
                              confirmedName: defaultApplication['confirmedName'],
                              applicationId: defaultApplication['id'],
                              dealId: arr[x]['dealId'],
                              folderId: defaultApplication['folderId'],
                            })) || [];

                            // Update documents in the service
                            this.dataService.updatedocuments(documents);
                          }
                        }
                      });


                    }


                  }
                })





              } else {
                if (this.altomateService.firstInitial2 == true) {
                  this.altomateService.firstInitial2 = false
                  this.altomateService.swalclose()
                }
              }
            })


          })

        } else {

        }
      })
    })
  }

  sortNoti() {
    return this.notificationLists.concat(this.allnotificationLists).sort((a, b) => b['date'] - a['date'])
  }

  ngOnInit(): void {
    if (this.platform.is('ios')) {
      SafeArea.getSafeAreaInsets().then(({ insets }) => {
        document.documentElement.style.setProperty('--safe-area-inset-customtop', `${insets['top']}px`);
        document.documentElement.style.setProperty('--safe-area-inset-custombottom', `${insets['bottom']}px`);
      });
    }

  }


  checkPermission(x) {

    let findPermission = this.authority.find(g => g['name'] == x && g['value'] == true)

    // console.log(this.isAdmin == false)
    // console.log(this.isAdmin)

    return this.isAdmin == false ? true : findPermission ? true : false

  }

  // monthSelected(ev, input) {
  //   let { _d } = ev;
  //   this.selectYear = _d;
  //   console.log(this.selectYear)
  //   this.picker.close()
  // }


  openMarket() {
    this.market.open(this.bundle[this.currentPlatform])
  }

  pathto(x) {
    this.router.navigate([x], { replaceUrl: true });
    // this.nav.navigateRoot();
  }

  getFCMToken() {

    // FCM.getToken().then((r) => {

    // })
    // this.fcm.getToken().then(token => {
    //   console.log(token)
    // }).catch((error) => {
    //   console.log('helo')
    // })
  }


  async update() {
    const userRef = ref(this.database, 'users/' + this.userid + '/default');

    await set(userRef, this.default);

    window.location.reload();

  }

  async update2(id) {
    const applicationRef = ref(this.database, 'applications/' + id);

    const snapshot = await get(applicationRef);

    if (snapshot.exists()) {
      const userRef = ref(this.database, 'users/' + this.userid + '/default');
      await set(userRef, id);
      window.location.reload();
    } else {
      this.altomateService.showMessage('This application does not exist in Altomate!', '', 'error');
    }

  }

  tabs = [
    {
      icon: 'assets/svg/Home.svg',
      selectedicon: 'assets/svg/Home-Green.svg',
      title: 'Home',
      path: 'tabs/tab1',
      name: 'Dashboard'
    },
    {
      icon: 'assets/svg/Accounting.svg',
      selectedicon: 'assets/svg/Accounting-Green.svg',
      title: 'Accounting',
      path: 'tabs/tab2',
      name: 'Accounting'
    },
    {
      icon: 'assets/svg/loan.svg',
      selectedicon: 'assets/svg/loan-green.svg',
      title: 'Loan',
      path: 'tabs/loan-calculator',
      name: 'Loan'
    },

    {
      icon: 'assets/svg/Company.svg',
      selectedicon: 'assets/svg/Company-Green.svg',
      title: 'Company',
      path: 'tabs/tab4',
      name: 'Company'
    },
  ]

  tabsMobile = [
    {
      icon: 'assets/svg/Home.svg',
      selectedicon: 'assets/svg/Home-Green.svg',
      title: 'Home',
      path: 'tabs/tab1',
      name: 'Dashboard'
    },
    {
      icon: 'assets/svg/loan.svg',
      selectedicon: 'assets/svg/loan-green.svg',
      title: 'Loan',
      path: 'tabs/loan-calculator',
      name: 'Loan'
    },
    {
      icon: 'assets/svg/Company.svg',
      selectedicon: 'assets/svg/Company-Green.svg',
      title: 'Company',
      path: 'tabs/tab4',
      name: 'Company'
    },
  ]

  /*{
    icon: 'assets/svg/Billing.svg',
    selectedicon: 'assets/svg/Billing-Green.svg',
    title: 'Billing',
    path: 'tabs/tab3'
  },*/
  // <----- FusionETA - Alvin (29 November 2023) - progress bar icon (nav bar 2) - Start ---->
  tabs2 = [
    {
      icon: 'assets/svg/checklist.svg',
      selectedicon: 'assets/svg/checklist-green.svg',
      title: 'Checklist',//add title 07.03.2024
      path: 'progress-bar'
    },
    // <----- FusionETA - Alvin (29 November 2023) - progress bar icon (nav bar 2) - Start ---->
    // <----- FusionETA - JY (27 September 2023) - 3 icon - Start ---->
    //{
    //  icon: 'assets/svg/Billing.svg',
    //  selectedicon: 'assets/svg/Billing-Green.svg',
    //  path: 'video-alert'
    // },
    // {
    //  icon: 'assets/svg/Company.svg',
    //  selectedicon: 'assets/svg/Company-Green.svg',
    //  path: 'tabs/tab4'
    //}
    // <----- FusionETA - JY (27 September 2023) - 3 icon - End ----->

  ]

  routerChecker(path) {

    if (this.router.url.includes(path)) {
      return true
    }

    return false
  }

  hideTab() {
    if (this.router.url.includes('/enterprise-partnership')) {
      return true
    }


    if (this.router.url.includes('/invitation-link')) {
      return true
    }

    if (this.router.url.includes('/mycompany-start')) {
      return true
    }

    if (this.router.url.includes('/transfer')) {
      return true
    }

    if (this.router.url.includes('/company-start-type')) {
      return true
    }

    if (this.router.url.includes('/document')) {
      return true
    }

    if (this.router.url.includes('/business-address')) {
      return true
    }

    if (this.router.url.includes('/company-secretarial')) {
      return true
    }

    if (this.router.url.includes('/all-requests')) {
      return true
    }

    if (this.router.url.includes('/all-files')) {
      return true
    }

    if (this.router.url.includes('/all-documents')) {
      return true
    }

    if (this.router.url.includes('/all-tasks')) {
      return true
    }

    if (this.router.url.includes('/landing')) {
      return true
    }

    if (this.router.url.includes('/start')) {
      return true
    }

    if (this.router.url.includes('/start-register')) {
      return true
    }

    if (this.router.url.includes('/start-login')) {
      return true
    }

    if (this.router.url.includes('/forgot-password')) {
      return true
    }

    if (this.router.url.includes('/document')) {
      return true
    }

    if (this.router.url.includes('/request-create')) {
      return true
    }

    if (this.router.url.includes('/change-password')) {
      return true
    }

    if (this.router.url.includes('/profile')) {
      return true
    }

    if (this.router.url.includes('/kyc')) {
      return true
    }

    if (this.router.url.includes('/bank-selection')) {
      return true
    }


    if (this.router.url.includes('/transactions-detail')) {
      return true
    }

    if (this.router.url.includes('/tab4-applications')) {
      return true
    }

    if (this.router.url.includes('/resetpassword')) {
      return true
    }

    if (this.router.url.includes('/mfa')) {
      return true
    }

    

    return false
  }

  hideTab2() {
    if (this.router.url.includes('/landing')) {
      return true
    }

    if (this.router.url.includes('/invitation-link')) {
      return true
    }


    if (this.router.url.includes('/company-start-type')) {
      return true
    }


    if (this.router.url.includes('/start')) {
      return true
    }

    if (this.router.url.includes('/start-register')) {
      return true
    }

    if (this.router.url.includes('/start-login')) {
      return true
    }

    if (this.router.url.includes('/forgot-password')) {
      return true
    }

    // if (this.router.url.includes('/transactions-detail')) {
    //   return true
    // }

    if (this.router.url.includes('/resetpassword')) {
      return true
    }

    if (this.router.url.includes('/tab4-applications')) {
      return true
    }

    if (this.router.url.includes('/mfa')) {
      return true
    }


    return false
  }


  change2(url, name) {

    if (this.isAdmin == true && ['Dashboard', 'Accounting', 'Loan'].includes(name)) {

      if (this.checkPermission(name)) {
        this.router.navigate([url], { replaceUrl: true });
      } else {
        this.altomateService.showMessage('Insufficient Permission', 'You do not have permission to enter this page!', 'info')
      }

    } else {
      this.router.navigate([url], { replaceUrl: true });
    }




  }


  change(url) {
    this.router.navigate([url], { replaceUrl: true });
  }

  async openMobile(marketid) {
    const modal = await this.modalCtrl.create({
      component: OpenMobilePage,
      cssClass: 'updateModal',
      backdropDismiss: false,
      swipeToClose: false,
      componentProps: {
        marketid: marketid
      }
    });


    return await modal.present();
  }

  async showUpdate(marketid) {
    const modal = await this.modalCtrl.create({
      component: UpdatePage,
      cssClass: 'updateModal',
      backdropDismiss: false,
      swipeToClose: false,
      componentProps: {
        marketid: marketid
      }
    });


    return await modal.present();
  }

  goDashboard() {
    this.nav.navigateRoot('tabs/tab1')
  }

  searchCompany() {
    if (this.searchCompanyKeyword != '') {
      this.http.get(`https://crm.eta-co.com.my/rest/427/ucaohz32xttqneoe/crm.deal.list.json?SELECT[]=TITLE&SELECT[]=UF_CRM_1638354326497&SELECT[]=UF_CRM_1619763409775&FILTER[%TITLE]=${this.searchCompanyKeyword}&FILTER[UF_CRM_1638354326497]!=`).subscribe((res) => {
        this.searchComapnyResult = res['result'].filter(g => g['UF_CRM_1619763409775'] == '5437' || g['UF_CRM_1619763409775'] == '1999' || g['UF_CRM_1619763409775'] == '2001')
      })
    } else {
      this.searchComapnyResult = []
    }
  }

  isFocus = false

  searchBarFocus() {
    this.isFocus = true
  }

  searchBarUnfocus() {
    this.isFocus = false
  }

  async signOut() {
    const auth = getAuth();


    await signOut(auth);
    localStorage.removeItem('login');
    this.dataService.updateUser({});
    this.dataService.updateFolders([]);
    this.dataService.updateInvoices([]);
    this.dataService.updateRequestDeal([]);
    this.dataService.clearDocument();
    this.dataService.removeAllApplication();
    this.altomateService.firstInitial = true;
    this.altomateService.firstInitial2 = true;
    this.nav.navigateRoot('start-login');

  }

  async showNotifications() {
    const modal = await this.modalCtrl.create({
      component: NotificationsPage,
      componentProps: {
        notifications: this.notificationLists
      }
    });

    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'confirm') {
      document.querySelector('#message').innerHTML = `Hello, ${data}!`;
    }
  }

}
