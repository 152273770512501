export const environment = {
  production: false
};

// export const baseUrl = 'http://localhost:5001/fusioneta-test/us-central1/widgets3'
export const baseUrl = 'https://us-central1-fusioneta-test.cloudfunctions.net/widgets3'
export const sgUrl = 'https://us-central1-fusioneta-test.cloudfunctions.net/sgWidget'
export const taxUrl = 'https://us-central1-fusioneta-test.cloudfunctions.net/taxWidget'
//export const accountingUrl = 'http://localhost:5001/fusioneta-test/us-central1/altomateAccounting'
export const xeroLink = 'https://test.altomate.io'
export const CLOUD_FUNCTION_WIDGETS_URL = "https://us-central1-fusioneta-test.cloudfunctions.net/widgets2"
export const TAX_SOURCE_ID = "UC_4CO0XC"
export const XERO_AC_REDIRECT_URI = "https://test.altomate.io/xero-access"
export const STAGE = "test"
export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyCbKA3oCYUcKv4XQb8g8t0dzxFEAzoLn04",
  authDomain: "fusioneta-test.firebaseapp.com",
  databaseURL: "https://fusioneta-test-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "fusioneta-test",
  storageBucket: "fusioneta-test.appspot.com",
  messagingSenderId: "167207020456",
  appId: "1:167207020456:web:d199f2e965a4a037e39f9d"
};
